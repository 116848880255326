export default {
  data() {
    return {
      substrateText: 'Методичка',
      title: '6 лайфхаков по\u00A0смешанному обучению',
      text: 'Скачайте методичку и\u00A0создайте обучение, которое работает',
      link: {
        text: 'Скачать',
        href: 'https://mts-link.ru/metodichka-kursy-new/',
      },
    }
  }
}
