<template>
    <Component :is="pageComponent"/>
</template>
<script>
import LayoutPrimary from "@/mixins/LayoutPrimary";
import ProductsWestudyDesktop from "@/components/Page/Products/Westudy/ProductsWestudyDesktop";
import ProductsWestudyMobile from "@/components/Page/Products/Westudy/ProductsWestudyMobile";

export default {
  name: 'ProductsWestudyPage',
  mixins: [LayoutPrimary],
  head() {
    return {
      title: 'МТС Линк Курсы — платформа для онлайн обучения и курсов',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'МТС Линк Курсы – это бесплатная Российская платформа от «МТС Линк» для создания онлайн-курсов и организации дистанционного обучения. Данная платформа идеально подходит всем: студентам, сотрудникам, клиентам и партнерам, т.к. имеет мощный внутренний функционал ► Переходите на сайт и начните использовать МТС Линк Курсы прямо сейчас!'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: '',
        },
      ],
      bodyAttrs: {
        class: 'grey-body'
      },
    };
  },
  computed: {
    pageComponent: (ctx) => ctx.$device.isMobile ? ProductsWestudyMobile : ProductsWestudyDesktop,
  }
}
</script>
