<template>
  <div class="westudy-head">
    <div class="westudy-head__content">
      <h1 class="westudy-head__content-title">Курсы от&nbsp;МТС&nbsp;Линк</h1>

      <div class="westudy-head__content-text">
        Платформа для создания онлайн&#8209;курсов
        и&nbsp;организации обучения
      </div>

      <VButtonLinkMTS href="#westudy-form" class="--violet --l">
        Попробовать бесплатно
      </VButtonLinkMTS>
    </div>
  </div>
</template>

<script>
import VButtonLinkMTS from "@/components/Common/MTS/VButtonLinkMTS";
export default {
  name: 'WestudyHeadMobile',
  components: { VButtonLinkMTS },
}
</script>

<style lang="scss" scoped>
.westudy-head {
  position: relative;
  border-radius: 0 0 24px 24px;
  height: 493px;
  background-image: url("~/assets/img/products-westudy/head_mobile.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  margin-bottom: 32px;
  background-position: center;

  &__content {
    padding: 20px;
    border-radius: 16px;
    background-color: $color-white;
    width: 100%;

    &-title {
      font-family: $font-mts-wide;
      font-size: 32px;
      line-height: 38px;
      font-weight: 400;
      letter-spacing: -0.02em;
      margin-bottom: 16px;
    }

    &-text {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 40px;
      max-width: 265px;
    }

    ::v-deep .v-button-link-mts {
      width: 100%;
    }
  }
}
</style>
