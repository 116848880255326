<template>
  <div class="westudy-analytics__container --outer">
    <div class="westudy-analytics">
      <div class="westudy-analytics__container">
        <h2 class="westudy-analytics__title">{{ title }}</h2>

        <div class="westudy-analytics__image">
          <picture>
            <source :srcset="image.srcTablet" type="image/jpg" media="(max-width: 991px)">
            <source :srcset="image.srcTabletWebp" type="image/webp" media="(max-width: 991px)">
            <source :srcset="image.srcWebp" type="image/webp">
            <img :src="image.src" :alt="image.alt">
          </picture>
        </div>

        <div class="westudy-analytics__block">
          <span class="westudy-analytics__block-text">{{ text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WestudyAnalyticsMixin from "@/components/Desktop/Products/Westudy/mixins/WestudyAnalyticsMixin";

export default {
  name: 'WestudyAnalyticsDesktop',
  mixins: [WestudyAnalyticsMixin],
}
</script>

<style lang="scss" scoped>
.westudy-analytics {
  border-radius: 24px;
  margin-bottom: 64px;
  padding: 64px 0;
  background-image: linear-gradient(0.641turn, rgba(236, 255, 159, 1) 4%, rgba(255, 255, 255, 1) 46%);

  @include screen-down('lg') {
    margin-bottom: 40px;
    padding: 40px 0;
  }

  &__container {
    max-width: 1344px;
    padding: 0 40px;
    margin: 0 auto;

    &.--outer {
      @include screen-down('lg') {
        max-width: none;
        padding: 0;
      }
    }
  }

  picture {
    display: block;
    line-height: 0;
  }

  &__title {
    font-size: 32px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    margin-bottom: 40px;

    @include screen-down('lg') {
      margin-bottom: 32px;
    }
  }

  &__image {
    border-radius: 24px;
    overflow: hidden;
    margin-bottom: 32px;
  }

  &__block {
    border-radius: 24px;
    background-color: #F2F3F7;
    padding: 56px;

    &-text {
      font-size: 20px;
      line-height: 27px;
    }
  }
}
</style>
