<template>
  <VCardMTS class="westudy-metodichka__card">
    <div class="westudy-metodichka__card-substrate --violet">
      {{ substrateText }}
    </div>
    <div class="westudy-metodichka__card-content">
      <h2 class="westudy-metodichka__card_title">{{ title }}</h2>
      <p class="westudy-metodichka__card_text">{{ text }}</p>
      <a :href="link.href" class="westudy-metodichka__card_link">{{
        link.text
      }}</a>
    </div>
  </VCardMTS>
</template>
<script>
import VCardMTS from '~/components/Common/MTS/VCardMTS.vue'
import WestudyMetodichkaMixin from '~/components/Desktop/Products/Westudy/mixins/WestudyMetodichkaMixin'
export default {
  name: 'WestudyMetodichkaMobile',
  components: { VCardMTS },
  mixins: [WestudyMetodichkaMixin],
}
</script>
<style lang="scss" scoped>
.westudy-metodichka {
  &__card {
    margin: 0 auto 32px;

    &-substrate {
      height: 76px;
      padding: 20px 32px;
      margin-bottom: -26px;
      border-radius: 24px 24px 0 0;
      background-position: right;
      background-repeat: no-repeat;
      color: $color-light;
      font-family: 'MTS Wide', Arial, sans-serif;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      z-index: 0;

      &.--violet {
        background-image: url('~/assets/img/main/main--premise/violet-bg.svg');
        background-color: #8743dd;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      background: #fff;
      min-height: 248px;
      border-radius: 24px;
      padding: 24px;
      z-index: 1;
    }

    &_title {
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      max-width: 287px;
      margin-bottom: 12px;
    }

    &_text {
      max-width: 359px;
      font-size: 17px;
      line-height: 24px;
    }

    &_link {
      display: block;
      width: max-content;
      text-decoration: none;
      margin-top: auto;
      margin-bottom: 0;
      border-bottom: 1px solid #1d2023;
      color: #1d2023;
      font-size: 17px;
      line-height: 24px;
    }
  }
}
</style>
