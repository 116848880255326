<template>
  <div class="webstudy-functions">
    <h2 class="webstudy-functions__title">{{ title }}</h2>

    <ul class="webstudy-functions__cards">
      <li
        v-for="(item, index) in cards"
        :key="index"
        class="webstudy-functions__cards-item"
      >
        <VIconMTS
          :name="item.icon"
          width="44"
          height="44"
          class="webstudy-functions__cards-item-icon"
        />

        <div v-html="item.title" class="webstudy-functions__cards-item-title"></div>

        <div class="webstudy-functions__cards-item-text">{{  item.text  }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
import WebstudyFunctionsMixin from "@/components/Desktop/Products/Westudy/mixins/WebstudyFunctionsMixin";
import VIconMTS from "@/components/Common/MTS/VIconMTS";

export default {
  name: 'WebstudyFundtionsDesktop',
  components: { VIconMTS },
  mixins: [WebstudyFunctionsMixin],
}
</script>

<style lang="scss" scoped>
.webstudy-functions {
  margin-bottom: 64px;

  @include screen-down('lg') {
    margin-bottom: 40px;
  }

  &__title {
    font-size: 32px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    margin: 0 auto 40px;

    @include screen-down('lg') {
      margin-bottom: 32px;
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: 483px 1fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 32px;
    list-style: none;
    padding: 0;
    margin: 0;

    @media (max-width: 1078px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, auto);
      gap: 20px;
    }

    &-item {
      background-color: #0A0311;
      border-radius: 24px;
      padding: 32px;
      color: $color-white;

      @include screen-down('lg') {
        padding: 20px;
        border-radius: 20px;
      }

      &:nth-child(1) {
        @media (max-width: 1078px) {
          order: 2;
        }
      }

      &:nth-child(2) {
        @media (max-width: 1078px) {
          order: 1;
          grid-column: span 2;

          ::v-deep br {
            display: none;
          }
        }
      }

      &:nth-child(3) {
        @media (max-width: 1078px) {
          order: 3;
        }
      }

      &:nth-child(4) {
        @media (max-width: 1078px) {
          order: 4;
          grid-column: span 2;

          ::v-deep br {
            display: none;
          }
        }
      }

      &-icon {
        margin-bottom: 63px;

        @include screen-down('lg') {
          margin-bottom: 40px;
        }
      }

      &-title {
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 16px;

        @include screen-down('lg') {
          font-size: 20px;
          line-height: 24px;
        }
      }

      &-text {
        font-size: 20px;
        font-weight: 400;
        line-height: 27px;

        @include screen-down('lg') {
          font-size: 17px;
          line-height: 24px;
        }
      }
    }
  }
}
</style>
