export default {
    data () {
        return {
            title: 'Полезные функции',
            cards: [
                {
                    icon: 'products-westudy/icon-setting',
                    title: 'Простой и\u00A0удобный<br> редактор курса',
                    text: 'Облегчает работу и\u00A0экономит время на\u00A0организации обучения'
                },
                {
                    icon: 'products-westudy/icon-statistics',
                    title: 'Интеграция с\u00A0платформой<br> для\u00A0проведения вебинаров',
                    text: 'Помогает повысить эффективность процесса обучения за\u00A0счет разнообразных типов занятий в\u00A0единой структуре курса'
                },
                {
                    icon: 'products-westudy/icon-filter',
                    title: 'Журнал курса <br>и\u00A0сквозная статистика',
                    text: 'Помогают следить за\u00A0успеваемостью и\u00A0усвоением знаний'
                },
                {
                    icon: 'products-westudy/icon-team',
                    title: 'Поддержка SSO <br>и\u00A0добавление модераторов',
                    text: 'Упрощают управление курсом'
                },
            ]
        }
    }
}
