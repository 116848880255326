<template>
  <div class="v-badge-mts" :class="{ '--active': showBadge }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BadgeMTS',
  props: {
    showBadge: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.v-badge-mts {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, calc(100% + 40px));
  width: 100%;
  max-width: max-content;
  background-color: #ecff9f;
  box-shadow: 0 18px 88px -4px #18274b24;
  transition: 0.3s ease-out;
  z-index: 9999;

  @include screen-down('md') {
    max-width: calc(100% - 40px);
  }

  &.--active {
    transform: translate(-50%, 0);
  }
  &.--m {
    padding: 15px 24px;
    border-radius: 8px;
    font-size: 20px;
    line-height: 28px;
  }
  &.--s {
    padding: 10px 24px;
    border-radius: 8px;
    font-size: 17px;
    line-height: 20px;
  }
  &.--xs {
    padding: 10px 16px;
    border-radius: 8px;
    font-size: 17px;
    line-height: 24px;
  }
  &.--xxs {
    padding: 8px 24px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
