// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/img/main/main--premise/violet-bg.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".westudy-metodichka__card[data-v-2b051ce4]{margin:0 auto 64px;max-width:1048px}@media(max-width:991px){.westudy-metodichka__card[data-v-2b051ce4]{margin-bottom:40px}}.westudy-metodichka__card-substrate[data-v-2b051ce4]{height:76px;padding:20px 32px;margin-bottom:-26px;border-radius:24px 24px 0 0;background-position:100%;background-repeat:no-repeat;color:#fafafa;font-family:\"MTS Wide\",Arial,sans-serif;font-size:14px;font-weight:700;line-height:20px;letter-spacing:.05em;text-transform:uppercase;z-index:0}.westudy-metodichka__card-substrate.--violet[data-v-2b051ce4]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-color:#8743dd}.westudy-metodichka__card-content[data-v-2b051ce4]{display:flex;flex-direction:column;background:#fff;border-radius:24px;padding:32px;z-index:1}@media(min-width:992px){.westudy-metodichka__card-content[data-v-2b051ce4]{min-height:192px}}@media(max-width:991px){.westudy-metodichka__card-content[data-v-2b051ce4]{min-height:260px}}@media(min-width:992px){.westudy-metodichka__card-content-wrapper[data-v-2b051ce4]{display:flex;justify-content:space-between}}.westudy-metodichka__card_title[data-v-2b051ce4]{width:100%;font-size:32px;font-weight:500;line-height:36px;max-width:500px}@media(max-width:991px){.westudy-metodichka__card_title[data-v-2b051ce4]{margin-bottom:12px}}.westudy-metodichka__card_text[data-v-2b051ce4]{max-width:359px;font-size:20px;line-height:28px}.westudy-metodichka__card_link[data-v-2b051ce4]{display:block;width:-moz-max-content;width:max-content;text-decoration:none;margin-top:auto;margin-bottom:0;border-bottom:1px solid #1d2023;color:#1d2023;font-size:17px;line-height:24px}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
