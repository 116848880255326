export default {
  data() {
    return {
      tariffs: [
        {
          head: {
            title: 'Бесплатный тариф',
            icon: 'products-westudy/icon-star',
          },
          title: 'Free',
          text: 'Базовые функции для\u00A0небольших команд',
          price: 'Бесплатно',
          hash: '#westudy-form',
          options: `
                        <ul>
                            <li>Доступ ко всему<br> функционалу платформы</li>
                            <li>До 50 <br>активных участников</li>
                            <li>Бесплатный пробный период</li>
                            <li>0.5 Гб</li>
                            <li>Мобильное приложение</li>
                        </ul>
                    `,
        },
        {
          head: null,
          title: 'Pro',
          text: 'Полный набор функций для\u00A0командной работы',
          price: 'от 5 995 ₽ <span>/в месяц</span>',
          hash: null,
          options: `
                    <ul>
                        <li>Неограниченное <br>количество курсов</li>
                        <li>От 50 до 500 <br>активных участниковв</li>
                        <li>Расширенная аналитика</li>
                        <li>Файловое хранилище 100 Гб</li>
                        <li>API</li>
                    </ul>
                    `,
          type: 'pro',
        },
        {
          head: null,
          title: 'Enterprise',
          text: 'Для крупных компаний со\u00A0сложной структурой',
          price: 'По запросу',
          hash: null,
          options: `
                        <ul>
                            <li>Неограниченное <br>количество курсов</li>
                            <li>До 5000 <br>активных участниковв</li>
                            <li>Расширенная аналитика</li>
                            <li>Файловое хранилище 150 Гб</li>
                            <li>API</li>
                            <li>Обучение и внедрение</li>
                        </ul>
                    `,
          type: 'enterprise',
        },
      ],
      showPopup: false,
      formPro: {
        formName: 'westudy-tariff-pro',
        idValue: 'c04d5a88-0dc7-4dd1-b5eb-f82b303cb42d',
        question:
          'Лид с лендинга https://mts-link.ru/products/westudy/. Заявка на Pro тариф от 5995 ₽ в месяц',
      },
      formEnterprise: {
        formName: 'westudy-tariff-enterprise',
        idValue: 'c04d5a88-0dc7-4dd1-b5eb-f82b303cb42d',
        question:
          'Лид с лендинга https://mts-link.ru/products/westudy/. Заявка на Enterprise тариф. Стоимость по запросу',
      },
      tariff: 'pro',
    }
  },
  computed: {
    form() {
      return this.tariff === 'pro' ? this.formPro : this.formEnterprise
    },
  },
  methods: {
    openPopup(tariff) {
      this.tariff = tariff
      this.showPopup = true
    },
    closePopup() {
      this.showPopup = false
    },
  },
}
