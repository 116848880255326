<template>
  <BadgeMTS :show-badge="showBadge" class="products-badge --xs">
    <div class="products-badge__content-wrapper">
      <VIconMTS
        name="mts/icon-mts--thunderbolt"
        width="24"
        height="24"
        fill="#1d2023"
      />
      <span class="products-badge__text">{{ text }}</span>
      <a :href="href" target="_blank" class="products-badge__link">Подробнее</a>
    </div>
  </BadgeMTS>
</template>
<script>
import BadgeMTS from '@/components/Common/BadgeMTS'
import VIconMTS from '@/components/Common/MTS/VIconMTS'
export default {
  name: 'ProductsBadgeMobile',
  components: { VIconMTS, BadgeMTS },
  props: {
    text: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showBadge: false,
      lastScrollTop: 0,
    }
  },
  methods: {
    hanldeScroll() {
      const top = window.pageYOffset
      if (this.lastScrollTop < top) {
        if (this.lastScrollTop > 400) {
          this.showBadge = true
        } else {
          this.showBadge = false
        }
      } else if (this.lastScrollTop > top) {
        this.showBadge = false
      }

      this.lastScrollTop = top
    },
  },
  mounted() {
    window.addEventListener('scroll', this.hanldeScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.hanldeScroll)
  },
}
</script>

<style lang="scss" scoped>
.products-badge {
  &__content-wrapper {
    display: flex;
    align-items: center;

    svg {
      align-self: start;
      flex: 0 0 24px;
    }
  }

  &__text {
    margin-left: 4px;
    border-right: 1px solid #969fa8;
    padding-right: 20px;
    color: $color-dark-2;
    font-family: $font-mts-compact;
    line-height: 21px;
  }

  &__link {
    font-family: $font-mts-compact;
    font-weight: 500;
    text-decoration: none;
    color: $color-dark-2;
    margin-left: 20px;
    transition: all 0.3s ease;
    @include hover-focus-active {
      color: $color-purple-2;
    }
  }
}
</style>
