<template>
  <div class="westudy-education">
    <div class="container">
      <div class="westudy-education__top">
        <h2 class="westudy-education__title">{{ title }}</h2>

        <div class="westudy-education__image">
          <picture>
            <source :srcset="image.srcTablet" media="(max-width: 991px)" type="image/jpg">
            <source :srcset="image.srcTabletWebp" media="(max-width: 991px)" type="image/webp">
            <source :srcset="image.srcWebp" type="image/webp">
            <img :alt="image.alt" :src="image.src">
          </picture>
        </div>

        <ul class="westudy-education__cards">
          <li v-for="(item, index) in cards" :key="index" class="westudy-education__cards-item">
            <span v-html="item"></span>
          </li>
        </ul>
      </div>
    </div>

    <div class="westudy-education__bottom">
      <div class="container">
        <ul class="westudy-education__list">
          <li v-for="(item, index) in list" :key="index" class="westudy-education__list-item">
            <div class="westudy-education__list-item-title" v-html="item.title"></div>
            <div v-html="item.text" class="westudy-education__list-item-text"></div>
          </li>
        </ul>

        <VButtonLinkMTS href="#westudy-form" class="--violet westudy-education__btn">
          Попробовать бесплатно
        </VButtonLinkMTS>
      </div>
    </div>
  </div>
</template>

<script>
import WestudyEducationMixin from "@/components/Desktop/Products/Westudy/mixins/WestudyEducationMixin";
import VButtonLinkMTS from "@/components/Common/MTS/VButtonLinkMTS";

export default {
  name: 'WestudyEducationDesktop',
  components: { VButtonLinkMTS },
  mixins: [WestudyEducationMixin],
}
</script>

<style lang="scss" scoped>
.westudy-education {
  border-radius: 24px;
  padding: 64px 0 0;
  margin-bottom: 64px;
  background-color: #0A0311;
  overflow: hidden;

  @include screen-down('lg') {
    margin-bottom: 40px;
    padding-top: 40px;
  }

  & .container {
    max-width: 1344px;
    padding: 0 40px;
  }

  picture {
    display: block;
    line-height: 0;
  }

  &__bottom {
    background: linear-gradient(0.042turn, rgba(135, 67, 221, 1) 0%, rgba(10, 3, 17, 255) 36%);
    padding-bottom: 64px;

    @include screen-down('lg') {
      padding-bottom: 40px;
    }
  }

  &__title {
    font-size: 32px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    color: $color-white;
    max-width: 746px;
    margin: 0 auto 40px;

    @include screen-down('lg') {
      margin-bottom: 32px;
    }
  }

  &__image {
    border-radius: 24px;
    overflow: hidden;
    margin-bottom: 32px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, min(140px));
    gap: 32px;
    list-style: none;
    padding: 0 0 72px;
    margin: 0;

    @include screen-down('lg') {
      gap: 20px;
      grid-template-rows: repeat(2, min(121px));
    }

    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 43px 32px;
      background-color: #1D1A20;
      border-radius: 24px;
      text-align: center;

      @include screen-down('lg') {
        padding: 20px;
        border-radius: 16px;
      }

      span {
        display: block;
        font-size: 20px;
        line-height: 27px;
        color: $color-white;
        max-width: 256px;
      }
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 32px;
    list-style: none;
    padding: 0;
    margin: 0 0 40px;
    text-align: center;

    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 32px;

      @include screen-down('lg') {
        padding: 20px;
      }

      &-title {
        display: flex;
        align-items: flex-end;
        font-family: $font-mts-wide;
        font-size: 36px;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: -0.01em;
        color: $color-yellow;
        height: 80px;
        margin-bottom: 16px;

        @include screen-down('lg') {
          height: 56px;
          font-size: 24px;
          line-height: 28px;
        }
      }

      &-text {
        font-size: 20px;
        font-weight: 400;
        line-height: 27px;
        color: $color-white;

        ::v-deep .tablet {
          display: none;
        }

        @include screen-down('lg') {
          line-height: 25px;
          max-width: 168px;

          ::v-deep .tablet {
            display: inline-block;
          }
        }
      }
    }
  }

  ::v-deep .v-button-link-mts {
    display: block;
    padding: 14px;
    width: 100%;
    max-width: 262px;
    border-radius: 8px;
    font-size: 17px;
    line-height: 24px;
  }

  &__btn {
    margin: 0 auto;
  }
}
</style>
