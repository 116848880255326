export default {
    data () {
        return {
            title: `
                <span>15 лет</span> помогаем<br> компаниям России и\u00A0СНГ<br> решать задачи внешних и\u00A0внутренних коммуникаций
            `,
            images: [
                {
                    src: require('~/assets/img/products-westudy/icon_call.png'),
                    srcWebp: require('~/assets/img/products-westudy/icon_call.webp'),
                    alt: 'call'
                },
                {
                    src: require('~/assets/img/products-westudy/icon_bell.png'),
                    srcWebp: require('~/assets/img/products-westudy/icon_bell.webp'),
                    alt: 'bell'
                },
                {
                    src: require('~/assets/img/products-westudy/icon_case.png'),
                    srcWebp: require('~/assets/img/products-westudy/icon_case.webp'),
                    alt: 'case'
                },
                {
                    src: require('~/assets/img/products-westudy/icon_microphone.png'),
                    srcWebp: require('~/assets/img/products-westudy/icon_microphone.webp'),
                    alt: 'microphone'
                },
                {
                    src: require('~/assets/img/products-westudy/icon_mail_eye.png'),
                    srcWebp: require('~/assets/img/products-westudy/icon_mail_eye.webp'),
                    alt: 'mail and eye'
                },
                {
                    src: require('~/assets/img/products-westudy/icon_cursor.png'),
                    srcWebp: require('~/assets/img/products-westudy/icon_cursor.webp'),
                    alt: 'bell'
                },
            ]
        }
    }
}
