<template>
  <div class="westudy-tariffs">
    <h2 class="westudy-tariffs__title">Тарифы</h2>

    <ul class="tariffs">
      <li
        v-for="(tariff, index) in tariffs"
        :key="index"
        :class="{ '--free': tariff.hash }"
        class="tariffs__item"
      >

        <div v-if="tariff.head" class="tariffs__item-head">
          <VIconMTS
            :name="tariff.head.icon"
            class="tariffs__item-head-icon"
            height="16"
            width="16"
          />
          <div class="tariffs__item-head-title">{{ tariff.head.title }}</div>
        </div>

        <div class="tariffs__item-wrapper">
          <div class="tariffs__item-title">{{ tariff.title }}</div>

          <div class="tariffs__item-text" v-html="tariff.text"></div>

          <div class="tariffs__item-price" v-html="tariff.price"></div>

          <VButtonLinkMTS
            v-if="tariff.hash"
            :href="tariff.hash"
            class="--violet-gradient --xl tariffs__item-btn"
          >
            Попробовать бесплатно
          </VButtonLinkMTS>

          <VButtonMTS
            v-else
            class="--dark-black --xl tariffs__item-btn"
            @click="openPopup(tariff.type)"
          >
            Оставить заявку
          </VButtonMTS>

          <div class="tariffs__item-list" v-html="tariff.options"></div>
        </div>
      </li>
    </ul>

    <Popup
      v-if="showPopup"
      :is-light="true"
      :is-closable="true"
      @close-popup="closePopup"
    >
      <div class="westudy-tariffs__form">
        <MTSApplicationUniversalForm
          form-title="Оставить заявку"
          :form-description="`Оставьте заявку и\u00A0мы свяжемся с\u00A0вами в\u00A0ближайшее время`"
          :form-user-comment="form.question"
          :form-name="form.formName"
          :form-btn-text="'Оставить заявку'"
          :form-btn-color="'--violet'"
          @close-popup="closePopup"
        />
      </div>
    </Popup>
  </div>
</template>

<script>
import WestudyTariffsMixin from "@/components/Desktop/Products/Westudy/mixins/WestudyTariffsMixin";
import VButtonLinkMTS from "@/components/Common/MTS/VButtonLinkMTS";
import VButtonMTS from "@/components/Common/MTS/VButtonMTS";
import VIconMTS from "@/components/Common/MTS/VIconMTS";
import Popup from "@/components/Common/Popup/Popup";
import MTSApplicationUniversalForm from "@/components/Common/Form/MTSApplicationUniversalForm";

export default {
  name: 'WestudyTariffsMobile',
  components: {
    MTSApplicationUniversalForm,
    Popup,
    VIconMTS,
    VButtonMTS,
    VButtonLinkMTS
  },
  mixins: [WestudyTariffsMixin],
}
</script>

<style lang="scss" scoped>
.westudy-tariffs {
  margin-bottom: 32px;

  &__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    margin-bottom: 20px;
  }

  &__form {
    background-color: $color-white;
    border-radius: 24px;
    padding: 24px;

    ::v-deep .mts-application-form__title {
      font-family: $font-mts-compact;
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      text-align: center;
      color: #1D2023;
      margin-bottom: 16px;
    }

    ::v-deep .mts-application-form__description {
      font-size: 17px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: #1D2023;
      margin-bottom: 16px;
    }

    &::v-deep .mts-form-result__image {
      width: fit-content;
    }
  }
}

.tariffs {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
  list-style: none;
  padding: 0;
  margin: 0;

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 24px;
    overflow: hidden;;
    margin-right: 32px;


    &:last-child {
      margin-right: 0;
    }

    &.--free {
      background: linear-gradient(123.59deg, #8743DD 0%, #8B57FB 98.44%);
      padding: 2px;
      border-radius: 24px;
    }

    &-head {
      color: $color-white;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 0;

      &-icon {

      }

      &-title {
        font-size: 17px;
        font-weight: 500;
        line-height: 24px;
        margin-left: 6px;
      }
    }

    &-wrapper {
      flex-grow: 1;
      background-color: $color-white;
      border-radius: 24px;
      padding: 20px;

      .--free & {
        border-radius: 0 0 24px 24px;
      }

    }

    &-title {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 16px;
    }

    &-text {
      font-size: 17px;
      line-height: 24px;
      margin-bottom: 28px;
      max-width: 250px;
      color: #0A0311;
    }

    &-price {
      font-family: $font-mts-wide;
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
      letter-spacing: -0.01em;
      margin-bottom: 20px;
      color: #000000;

      ::v-deep span {
        font-size: 12px;
        line-height: 17px;
        color: #969FA8;
        font-family: $font-mts-compact;
      }
    }

    &-btn {
      display: block;
      margin-bottom: 20px;
      width: 100%;
    }

    &-list {
      padding-top: 20px;
      border-top: 2px solid #BCC3D080;

      ::v-deep ul {
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 17px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;

        li {
          background: url(~assets/img/icons/products-westudy/icon-check-purple.svg) no-repeat;
          padding-left: 27px;
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
