<template>
  <div class="westudy-help">
    <div class="westudy-help__image --1">
      <picture v-if="images[4]">
        <source :srcset="images[4].srcWebp" type="image/webp">
        <img
          :src="images[4].src"
          :alt="images[4].alt"
        >
      </picture>
    </div>

    <div class="westudy-help__text" v-html="title"></div>

    <div class="westudy-help__image --2">
      <picture v-if="images[5]">
        <source :srcset="images[5].srcWebp" type="image/webp">
        <img
          :src="images[5].src"
          :alt="images[5].alt"
        >
      </picture>
    </div>
  </div>
</template>

<script>
import WestudyHelpMixin from "@/components/Desktop/Products/Westudy/mixins/WestudyHelpMixin";

export default {
  name: 'WestudyHelpMobile',
  mixins: [WestudyHelpMixin],
}
</script>

<style lang="scss" scoped>
.westudy-help {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 16px;
  border-radius: 24px;
  margin-bottom: 32px;
  background-color: $color-white;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(0.43turn, rgba(135, 67, 221, 0.3) 0%, rgba(236, 255, 159, 0.3) 100%);
  }

  &__text {
    font-family: $font-mts-wide;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    max-width: 683px;
    text-align: center;
    letter-spacing: -0.01em;
    margin-bottom: 16px;

    ::v-deep span {
      color: #8743DD;
    }

    ::v-deep br.desktop {
      display: none;
    }
  }

  &__image {
    z-index: 1;

    &.--1 {
      width: 104px;
      margin-bottom: 20px;
    }

    &.--2 {
      width: 63px;
    }
  }
}
</style>
