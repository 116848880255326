<template>
  <div class="westudy-analytics">
    <div class="container">
      <h2 class="westudy-analytics__title">{{ title }}</h2>

      <div class="westudy-analytics__image">
        <picture>
          <source :srcset="image.srcMobileWebp" type="image/webp">
          <img :src="image.srcMobile" :alt="image.alt">
        </picture>
      </div>

      <div class="westudy-analytics__block">
        <span class="westudy-analytics__block-text">{{ text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import WestudyAnalyticsMixin from "@/components/Desktop/Products/Westudy/mixins/WestudyAnalyticsMixin";

export default {
  name: 'WestudyAnalyticsMobile',
  mixins: [WestudyAnalyticsMixin],
}
</script>

<style lang="scss" scoped>
.westudy-analytics {
  border-radius: 24px;
  margin-bottom: 32px;
  padding: 32px 0;
  background-color: $color-white;

  & .container {
    padding: 0 20px;
  }

  picture {
    display: block;
    line-height: 0;
  }

  &__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    max-width: 315px;
    margin: 0 auto 20px;
  }

  &__image {
    border-radius: 24px;
    overflow: hidden;
    margin-bottom: 20px;
  }

  &__block {
    border-radius: 24px;
    background-color: #F2F3F7;
    padding: 20px;

    &-text {
      font-size: 20px;
      line-height: 27px;
    }
  }
}
</style>
