// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/img/products-westudy/head_mobile.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".westudy-head[data-v-8e3d2d0e]{position:relative;border-radius:0 0 24px 24px;height:493px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:cover;padding:20px;margin-bottom:32px;background-position:50%}.westudy-head__content[data-v-8e3d2d0e]{padding:20px;border-radius:16px;background-color:#fff;width:100%}.westudy-head__content-title[data-v-8e3d2d0e]{font-family:MTS Wide,Arial,sans-serif;font-size:32px;line-height:38px;font-weight:400;letter-spacing:-.02em;margin-bottom:16px}.westudy-head__content-text[data-v-8e3d2d0e]{font-size:20px;line-height:26px;margin-bottom:40px;max-width:265px}.westudy-head__content[data-v-8e3d2d0e] .v-button-link-mts{width:100%}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
