<template>
  <div class="westudy-help">
    <div class="westudy-help__text" v-html="title"></div>

    <div
      v-for="(image, index) in images"
      :key="index"
      class="westudy-help__image"
      :class="`item-${index + 1}`"
    >
      <picture>
        <source :srcset="image.srcWebp" type="image/webp">
        <img :src="image.src" :alt="image.alt">
      </picture>
    </div>
  </div>
</template>

<script>
import WestudyHelpMixin from "@/components/Desktop/Products/Westudy/mixins/WestudyHelpMixin";

export default {
  name: 'WestudyHelpDesktop',
  mixins: [WestudyHelpMixin],
}
</script>

<style lang="scss" scoped>
.westudy-help {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 92px 0;
  border-radius: 24px;
  margin-bottom: 64px;
  overflow: hidden;
  background-color: $color-white;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(0.43turn, rgba(135, 67, 221, 0.3) 0%, rgba(236, 255, 159, 0.3) 100%);
  }

  @include screen-down('lg'){
    padding: 99px 0 126px;
    margin-bottom: 40px;
  }

  &__text {
    font-family: $font-mts-wide;
    font-size: 32px;
    font-weight: 400;
    line-height: 36px;
    max-width: 683px;
    text-align: center;

    ::v-deep span {
      color: #8743DD;
    }

    @media (max-width: 1100px) {
      font-size: 24px;
      line-height: 28px;
      max-width: 490px;
    }
  }

  &__image {
    position: absolute;

    &.item-1 {
      width: 120px;
      left: 16%;
      top: 24px;

      @media (max-width: 1100px) {
        width: 106px;
      }
    }

    &.item-2 {
      width: 65px;
      left: 8%;
      top: 138px;

      @include screen-down('lg'){
        width: 57px;
        left: 5%;
        top: 147px;
      }
    }

    &.item-3 {
      width: 78px;
      left: 19%;
      bottom: 26px;

      @include screen-down('lg'){
        width: 68px;
        left: 21%;
      }
    }

    &.item-4 {
      width: 65px;
      right: 19%;
      top: 20px;

      @include screen-down('lg'){
        width: 65px;
        right: 20%;
        top: 30px;
      }
    }

    &.item-5 {
      width: 120px;
      right: 4%;
      top: 128px;

      @include screen-down('lg'){
        width: 96px;
        right: 6%;
        top: 143px;
      }
    }

    &.item-6 {
      width: 84px;
      right: 18%;
      bottom: 24px;

      @include screen-down('lg'){
        width: 84px;
        right: 28%;
        bottom: 10px;
      }
    }
  }
}
</style>
