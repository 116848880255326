<template>
  <div class="popup" :class="{ 'popup--light': isLight }" @mousedown="clickOutsideContent($event)">
    <div class="popup-body">
      <button class="popup__close-button" @click="closePopup">
        <VIconMTS
          v-if="isClosable"
          :name="'mts/icon-mts--white-cross'"
          :width="28"
          :height="28"
        />
      </button>
      <slot />
    </div>
  </div>
</template>

<script>
import VIconMTS from '~/components/Common/MTS/VIconMTS.vue';

export default {
  name: 'Popup',
  components: {VIconMTS},
  props: {
    isLight: {
      type: Boolean,
      default: false,
    },
    isClosable: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    closePopup() {
      this.$emit('close-popup')
    },
    clickOutsideContent(event) {
      if (!event.target.closest('.popup-body')) {
        this.closePopup()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 99999;
  padding-top: 45px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: auto;

  @include screen-down('md') {
    padding-top: 35px;
  }

  &--light {
    background-color: rgba(29, 32, 35, 0.50);
    backdrop-filter: blur(10px);
    align-items: center;
  }

  &-body {
    position: relative;
    min-width: 450px;

    @media (max-width: 400px){
      min-width: auto;
      width: 100%;
    }

    .popup--light & {
      min-width: auto;
      padding: 0 20px;
    }
  }

  &--specialisations {
    .popup-content {
      @include screen-down('md') {
        width: 100%;
      }
    }
  }

  &__close-button {
    position: absolute;
    display: block;
    z-index: 250;
    right: -36px;
    top: 0;
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;

    @include screen-down('md') {
      right: 0px;
      top: -36px;
    }
  }
}
</style>
