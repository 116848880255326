<template>
  <div class="westudy-head">
    <div class="westudy-head__content">
      <h1 class="westudy-head__content-title">Курсы от&nbsp;МТС&nbsp;Линк</h1>

      <div class="westudy-head__content-text">
        Платформа для создания онлайн&#8209;курсов
        и&nbsp;организации обучения
      </div>

      <VButtonLinkMTS href="#westudy-form" class="--violet --l">
        Попробовать бесплатно
      </VButtonLinkMTS>
    </div>
  </div>
</template>

<script>
import VButtonLinkMTS from "@/components/Common/MTS/VButtonLinkMTS";
export default {
  name: 'WestudyHeadDesktop',
  components: { VButtonLinkMTS },
}
</script>

<style lang="scss" scoped>
.westudy-head {
  position: relative;
  border-radius: 24px;
  height: 597px;
  background-image: url("~/assets/img/products-westudy/head.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 56px;
  max-width: 1264px;
  margin: 0 auto 64px;
  background-position: center;

  @media (max-width: 1360px) {
    margin-right: 40px;
    margin-left: 40px;
  }

  @include screen-down('lg'){
    margin: 0 0 40px;
    padding: 40px;
    background-image: url("~/assets/img/products-westudy/head_tablet.jpg");
    background-position: left;
  }

  &__content {
    padding: 48px;
    border-radius: 24px;
    background-color: $color-white;
    width: 700px;
    z-index: 1;

    @include screen-down('lg') {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 40px;
    }

    &-title {
      font-family: $font-mts-wide;
      font-size: 60px;
      line-height: 63px;
      font-weight: 400;
      letter-spacing: -0.02em;
      margin-bottom: 16px;
    }

    &-text {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 40px;
      max-width: 368px;

      @include screen-down('lg') {
        text-align: center;
        margin-bottom: 32px;
      }
    }

    ::v-deep .v-button-link-mts {
      padding: 14px 22px;
    }
  }
}
</style>
