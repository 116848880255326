<template>
  <div class="webstudy-functions">
    <h2 class="webstudy-functions__title">{{ title }}</h2>

    <ul class="webstudy-functions__cards">
      <li
        v-for="(item, index) in cards"
        :key="index"
        class="webstudy-functions__cards-item"
      >
        <VIconMTS
          :name="item.icon"
          width="44"
          height="44"
          class="webstudy-functions__cards-item-icon"
        />

        <div v-html="item.title" class="webstudy-functions__cards-item-title"></div>

        <div class="webstudy-functions__cards-item-text">{{  item.text  }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
import WebstudyFunctionsMixin from "@/components/Desktop/Products/Westudy/mixins/WebstudyFunctionsMixin";
import VIconMTS from "@/components/Common/MTS/VIconMTS";

export default {
  name: 'WebstudyFundtionsMobile',
  components: { VIconMTS },
  mixins: [WebstudyFunctionsMixin],
}
</script>

<style lang="scss" scoped>
.webstudy-functions {
  margin-top: 32px;
  margin-bottom: 32px;

  &__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    margin: 0 auto 20px;
  }

  &__cards {
    list-style: none;
    padding: 0;
    margin: 0;

    &-item {
      background-color: #0A0311;
      border-radius: 24px;
      padding: 20px;
      color: $color-white;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      &:nth-child(1) {
        order: 2;
      }

      &:nth-child(2) {
        order: 1;
      }

      &-icon {
        margin-bottom: 40px;
      }

      &-title {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 16px;
      }

      &-text {
        font-size: 17px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
</style>
