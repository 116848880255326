// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/img/products-westudy/head.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../assets/img/products-westudy/head_tablet.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".westudy-head[data-v-ba7ce15e]{position:relative;border-radius:24px;height:597px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:cover;padding:56px;max-width:1264px;margin:0 auto 64px;background-position:50%}@media(max-width:1360px){.westudy-head[data-v-ba7ce15e]{margin-right:40px;margin-left:40px}}@media(max-width:991px){.westudy-head[data-v-ba7ce15e]{margin:0 0 40px;padding:40px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-position:0}}.westudy-head__content[data-v-ba7ce15e]{padding:48px;border-radius:24px;background-color:#fff;width:700px;z-index:1}@media(max-width:991px){.westudy-head__content[data-v-ba7ce15e]{display:flex;flex-direction:column;align-items:center;width:100%;padding:40px}}.westudy-head__content-title[data-v-ba7ce15e]{font-family:MTS Wide,Arial,sans-serif;font-size:60px;line-height:63px;font-weight:400;letter-spacing:-.02em;margin-bottom:16px}.westudy-head__content-text[data-v-ba7ce15e]{font-size:20px;line-height:26px;margin-bottom:40px;max-width:368px}@media(max-width:991px){.westudy-head__content-text[data-v-ba7ce15e]{text-align:center;margin-bottom:32px}}.westudy-head__content[data-v-ba7ce15e] .v-button-link-mts{padding:14px 22px}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
